<template>
  <div class="home">
    <navtop />
    <menuNav />
    <div style="width: 100%; position: relative">
      <!-- 轮播图 -->
      <!-- <el-carousel height="730px">
        <el-carousel-item v-for="item in 4" :key="item">
          <img
            src="../assets/image/banner1.png"
            width="100%"
            height="730"
            alt=""
          />
        </el-carousel-item>
      </el-carousel> -->
      <img src="../assets/image/banner1.png" width="100%" height="730" alt="" />
      <!-- 学科 -->
      <!-- <div class="choosebox">
        <span style="font-size: 16px; color: #fff; font-weight: 700"
          ><img src="../assets/image/ding.png" alt="" />小学</span
        ><span>语文</span><span>数学</span><span>英语</span>
        <span style="font-size: 16px; color: #fff; font-weight: 700"
          ><img src="../assets/image/ding.png" alt="" />初中</span
        ><span>语文</span><span>数学</span><span>英语</span><span>物理</span
        ><span>化学</span>
        <span style="margin-left: 35px">更多精彩内容&gt;&gt;</span>
      </div> -->
    </div>
    <div style="width: 1200px; margin: 0 auto">
      <div style="margin: 20px">
        <img src="../assets/image/img1.png" alt="" style="margin-right: 13px" />
        <img src="../assets/image/img2.png" alt="" style="margin-right: 13px" />
        <img src="../assets/image/img3.png" alt="" style="margin-right: 13px" />
        <img src="../assets/image/img4.png" alt="" @click="getMoney()" style="cursor: pointer;" />
      </div>
      <!-- 同步课程 -->
      <div class="clearfix">
        <div style="text-align: center; margin: 40px">
          <img src="../assets/image/indexTb.png" alt="" />
        </div>
        <div
          class="content"
          v-for="item in list.sync"
          :key="item.course_id"
          @click="getDetails(1, item)"
        >
          <img
            src="../assets/image/course.jpg"
            style="border-radius: 16px 16px 0 0; width: 264px"
            alt=""
          />
          <div class="contentbtm">
            <div class="title">{{ item.course_name }}</div>
            <div class="tips">
              <span
                v-if="item.course_free == '0'"
                class="fl"
                style="
                  background-color: #ffca45;
                  padding: 0 10px;
                  color: #fff;
                  border-radius: 6px;
                "
                >VIP</span
              >
              <span
                v-else
                class="fl"
                style="
                  background-color: #57da67;
                  padding: 0 10px;
                  color: #fff;
                  border-radius: 6px;
                "
                >可试听</span
              >
              <span class="fr" style="color: #87898d"
                >{{ item.course_views }}人观看</span
              >
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-bottom: 70px">
        <el-button type="primary" round @click="$router.push('/synchronous')"
          >点击查看更多 &gt;</el-button
        >
      </div>
      <!-- 专题课程 -->
      <div class="clearfix">
        <div style="text-align: center; margin: 40px">
          <img src="../assets/image/indexZT.png" alt="" />
        </div>
        <div style="text-align: center; margin-bottom: 30px">
          <img src="../assets/image/1.png" alt="" style="margin-right: 36px" />
          <img src="../assets/image/2.png" alt="" style="margin-right: 36px" />
          <img src="../assets/image/3.png" alt="" style="margin-right: 36px" />
          <img src="../assets/image/4.png" alt="" />
        </div>
        <!-- 四竖列 -->
        <!-- 学科 -->
        <div class="fl">
          <div
            class="content2 clearfix"
            v-for="item in list.special && list.special.xk"
            :key="item.course_id"
            @click="getDetails(2, item)"
          >
            <img
              src="../assets/image/course.jpg"
              style="border-radius: 16px 16px 0 0; width: 264px"
              alt=""
            />
            <div class="contentbtm clearfix">
              <div class="title">{{ item.course_name }}</div>
              <div class="tips">
                <span
                  v-if="item.course_free == '0'"
                  class="fl"
                  style="
                    background-color: #ffca45;
                    padding: 0 10px;
                    color: #fff;
                    border-radius: 6px;
                  "
                  >VIP</span
                >
                <span
                  v-else
                  class="fl"
                  style="
                    background-color: #57da67;
                    padding: 0 10px;
                    color: #fff;
                    border-radius: 6px;
                  "
                  >可试听</span
                >
                <span class="fr" style="color: #87898d"
                  >{{ item.course_views }}人观看</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 艺术 -->
        <div class="fl">
          <div
            class="content2 clearfix"
            v-for="item in list.special && list.special.ys"
            :key="item.course_id"
            @click="getDetails(2, item)"
          >
            <img
              src="../assets/image/course.jpg"
              style="border-radius: 16px 16px 0 0; width: 264px"
              alt=""
            />
            <div class="contentbtm clearfix">
              <div class="title">{{ item.course_name }}</div>
              <div class="tips">
                <span
                  v-if="item.course_free == '0'"
                  class="fl"
                  style="
                    background-color: #ffca45;
                    padding: 0 10px;
                    color: #fff;
                    border-radius: 6px;
                  "
                  >VIP</span
                >
                <span
                  v-else
                  class="fl"
                  style="
                    background-color: #57da67;
                    padding: 0 10px;
                    color: #fff;
                    border-radius: 6px;
                  "
                  >可试听</span
                >
                <span class="fr" style="color: #87898d"
                  >{{ item.course_views }}人观看</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 安全 -->
        <div class="fl">
          <div
            class="content2 clearfix"
            v-for="item in list.special && list.special.aq"
            :key="item.course_id"
            @click="getDetails(2, item)"
          >
            <img
              src="../assets/image/course.jpg"
              style="border-radius: 16px 16px 0 0; width: 264px"
              alt=""
            />
            <div class="contentbtm clearfix">
              <div class="title">{{ item.course_name }}</div>
              <div class="tips">
                <span
                  v-if="item.course_free == '0'"
                  class="fl"
                  style="
                    background-color: #ffca45;
                    padding: 0 10px;
                    color: #fff;
                    border-radius: 6px;
                  "
                  >VIP</span
                >
                <span
                  v-else
                  class="fl"
                  style="
                    background-color: #57da67;
                    padding: 0 10px;
                    color: #fff;
                    border-radius: 6px;
                  "
                  >可试听</span
                >
                <span class="fr" style="color: #87898d"
                  >{{ item.course_views }}人观看</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 其他 -->
        <div class="fl">
          <div
            class="content2 clearfix"
            v-for="item in list.special && list.special.qt"
            :key="item.course_id"
            @click="getDetails(2, item)"
          >
            <img
              src="../assets/image/course.jpg"
              style="border-radius: 16px 16px 0 0; width: 264px"
              alt=""
            />
            <div class="contentbtm clearfix">
              <div class="title">{{ item.course_name }}</div>
              <div class="tips">
                <span
                  v-if="item.course_free == '0'"
                  class="fl"
                  style="
                    background-color: #ffca45;
                    padding: 0 10px;
                    color: #fff;
                    border-radius: 6px;
                  "
                  >VIP</span
                >
                <span
                  v-else
                  class="fl"
                  style="
                    background-color: #57da67;
                    padding: 0 10px;
                    color: #fff;
                    border-radius: 6px;
                  "
                  >可试听</span
                >
                <span class="fr" style="color: #87898d"
                  >{{ item.course_views }}人观看</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-bottom: 70px">
        <el-button type="primary" round @click="$router.push('/topiccourse')"
          >点击查看更多 &gt;</el-button
        >
      </div>
    </div>
    <div class="indexfooter">
      <div style="width: 1200px; margin: 0 auto" class="clearfix">
        <div class="fl">
          <div class="school">
            合作院校
            <div class="line"></div>
          </div>
          <div class="schoolname">山东省聊城市冠县第二实验小学</div>
          <div class="schoolname">江西省上饶市万年县第一小学</div>
          <div class="schoolname">河北省石家庄市高邑县高邑四中</div>
          <div class="schoolname">四川省广安市邻水县兴仁镇中心小学</div>
          <div class="schoolname">... ...</div>
        </div>
        <div class="fr" style="position: relative">
          <div class="fl clock">
            <div style="padding-top: 30px; margin-bottom: 20px">
              <span style="font-size: 28px">2036</span><span>所</span>
            </div>
            <div>学校广泛应用</div>
          </div>
          <div class="fr">
            <div class="partner">合作伙伴</div>
            <img src="../assets/image/partner.png" alt="" />
          </div>
        </div>
      </div>
      <div style="margin-top: 80px">
        <Footer />
      </div>
    </div>
    <!-- 激活弹框 -->
    <el-dialog
      title="会员激活"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      style="margin-top: 25vh"
    >
      <div style="text-align: center">当前会员未激活，请先激活会员</div>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          size="small"
          @click="centerDialogVisible = false"
          style="padding: 9px 25px"
          >取 消</el-button
        >
        <el-button round size="small" type="danger" @click="getPay(1)"
          >学习卡激活</el-button
        >
        <el-button round size="small" type="primary" @click="getPay(2)"
          >充值中心</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import URL from "../api/index";
import navtop from "@/components/navtop.vue";
import menuNav from "@/components/menuNav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "Home",
  data() {
    return {
      centerDialogVisible: false,
      list: {},
    };
  },
  components: {
    navtop,
    menuNav,
    Footer,
  },
  mounted() {
    this.getinit();
  },
  methods: {
     // 充值中心
    getMoney() {
      if (localStorage.getItem("loginInfo")) {
        this.$router.push("/voucherCenter");
      } else {
        this.$confirm("此操作需要先进行登录~", "提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
    },
    getinit() {
      this.$post(URL.getcourselist).then((res) => {
        this.list = res.result;
      });
    },
    getDetails(res, item) {
      if (item.course_free == "0") {
        // vip
        if (localStorage.getItem("loginInfo")) {
          if (localStorage.getItem("vip") == 1) {
            // vip
            if (res == 1) {
              this.$router.push(`/synchronous/details?id=${item.course_id}`);
            } else {
              this.$router.push(
                `/topiccourse/topicdetails?id=${item.course_id}`
              );
            }
          } else {
            // 不是vip
            this.centerDialogVisible = true;
          }
        } else {
          this.$confirm("此操作需要先进行登录~", "提示", {
            confirmButtonText: "去登录",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push("/login");
            })
            .catch(() => {});
        }
      } else {
        // 可试听
        if (res == 1) {
          this.$router.push(`/synchronous/details?id=${item.course_id}`);
        } else {
          this.$router.push(`/topiccourse/topicdetails?id=${item.course_id}`);
        }
      }
    },
    getPay(id) {
      this.centerDialogVisible = false;
      if (id == 1) {
        this.$router.push("/cardActivation");
      } else {
        this.$router.push("/voucherCenter");
      }
    },
  },
};
</script>
<style lang='less' scoped>
.choosebox {
  position: absolute;
  top: 40px;
  left: 26%;
  width: 832px;
  height: 55px;
  line-height: 55px;
  padding-left: 20px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  img {
    margin-right: 10px;
  }
  span {
    cursor: pointer;
    color: #d8d8d8;
    font-size: 14px;
    padding: 0 14px;
  }
}
.content {
  cursor: pointer;
  float: left;
  margin: 0 18px 34px;
  width: 264px;
  height: 244px;
  background: #ffffff;
  opacity: 1;
  border-radius: 16px;
  .contentbtm {
    padding: 5px 15px;
    .title {
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 22px;
    }
  }

  .tips {
    font-size: 14px;
  }
}
.content2 {
  cursor: pointer;
  margin: 0 18px 34px;
  width: 264px;
  height: 244px;
  background: #ffffff;
  opacity: 1;
  border-radius: 16px;
  .contentbtm {
    padding: 5px 15px;
    .title {
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 22px;
    }
  }

  .tips {
    font-size: 14px;
  }
}
.indexfooter {
  padding-top: 40px;
  background-color: #f2f8ff;
  height: 400px;
  .school {
    font-size: 36px;
    color: #333333;
    letter-spacing: 2px;
    .line {
      margin: 10px 0;
      width: 67px;
      border: 2px solid #2c84ff;
    }
  }
  .schoolname {
    line-height: 40px;
  }
  .clock {
    position: absolute;
    top: 70px;
    left: -270px;
    text-align: center;
    color: #fff;
    width: 298px;
    height: 141px;
    letter-spacing: 2px;
    background: #2c84ff;
  }
  .partner {
    font-size: 30px;
    position: absolute;
    top: 30px;
    left: 240px;
  }
}
</style>