<template>
  <div class="box">
    <div class="content clearfix">
      <div class="fl">
        <img src="../assets/image/soulaoshi.png" alt="" />
        <span
          style="
            font-weight: 700;
            margin-left: 10px;
            font-size: 24px;
            letter-spacing: 1px;
          "
          >优质教育资源</span
        >
      </div>
      <div class="fr">
        <el-button type="text" v-if="islogin" @click="getOut()"
          >{{ userinfo.user_nicename }},退出登录</el-button
        >
        <el-button type="text" v-else @click="$router.push('/login')"
          >在这里登录~</el-button
        >
        <el-button
          type="danger"
          size="small"
          v-if="!isVIP"
          round
          @click="getActivation"
          >学习卡激活</el-button
        >
        <el-button
          type="danger"
          size="small"
          round
          v-else
          @click="getActivation"
          >已激活</el-button
        >
        <el-button type="primary" size="small" round @click="getMoney"
          >充值中心</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import URL from "../api/index";
export default {
  name: "navtop",
  data() {
    return {
      islogin: false,
      userinfo: {},
      isVIP: false,
    };
  },
  mounted() {
    // 是否登录
    this.userinfo = JSON.parse(localStorage.getItem("loginInfo"));
    if (this.userinfo) {
      this.islogin = true;
      // 是否 vip
      var data = { token: this.userinfo.token, md_id: 32 };
      this.$post(URL.getisVIP, data).then((res) => {
        console.log(res);
        if (res.result.status == 1) {
          this.isVIP = true;
          localStorage.setItem("vip", 1);
        }
      });
    }
  },
  methods: {
    getOut() {
      this.$confirm("确定要退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.clear();
          this.islogin = false;
          this.isVIP = false;
          if (this.$route.path!="/") {
            this.$router.push("/");
          }
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {});
    },
    // 学习卡激活
    getActivation() {
      if (localStorage.getItem("loginInfo")) {
        if (!this.isVIP) {
          this.$router.push("/cardActivation");
        }
      } else {
        this.$confirm("此操作需要先进行登录~", "提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
    },
    // 充值中心
    getMoney() {
      if (localStorage.getItem("loginInfo")) {
        this.$router.push("/voucherCenter");
      } else {
        this.$confirm("此操作需要先进行登录~", "提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      }
    },
  },
};
</script>


<style scoped lang="less">
.box {
  line-height: 70px;
  background-color: #fff;
  .content {
    margin: 0 auto;
    width: 1200px;
    img {
      vertical-align: middle;
    }
  }
}
</style>
