<template>
  <div class="box">
    <ul class="content">
      <li
        v-for="item in list"
        :key="item.name"
        :class="{ active: path == item.route }"
        @click="goPath(item)"
      >
        {{ item.name }}
      </li>
      <!-- <li @click="goTestpaper()">智能组卷系统</li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: "menuNav",
  data() {
    return {
      path: "",
      list: [
        { route: "/", name: "首页" },
        { route: "/resource", name: "备课资源" },
        { route: "/synchronous", name: "同步课程" },
        { route: "/topiccourse", name: "专题课程" },
        { route: "/testPaper", name: "智能组卷系统" },
      ],
    };
  },
  mounted() {
    this.path = this.$route.matched[0].path ? this.$route.matched[0].path : "/";
    // this.path = this.$route.path;
  },
  methods: {
    // goTestpaper() {
    //   window.open("http://www.rujiaowang.net/edu/rujiaoyun/#/testpaper");
    // },
    goPath(item) {
      if (this.path != item.route && item.route != "/testPaper") {
        this.$router.push(item.route);
      }
      if (item.route == "/testPaper") {
        if (!localStorage.getItem("loginInfo")) {
          this.$confirm("此操作需要先进行登录~", "提示", {
            confirmButtonText: "去登录",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push("/login");
            })
            .catch(() => {});
        } else {
          this.$router.push(item.route);
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.box {
  height: 60px;
  line-height: 60px; //yebushibuai jiushi zaiyqi ji
  background-color: #2c84ff;
  .content {
    cursor: pointer;
    margin: 0 auto;
    width: 1200px;
    li {
      display: inline-block;
      color: #fff;
      padding: 0 40px;
      letter-spacing: 2px;
    }
    li:hover {
      background-color: #64a5ff;
    }
    .active {
      background-color: #64a5ff;
    }
  }
}
</style>
