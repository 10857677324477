
// 登录注册
var getlogin = 'https://api.rujiaowang.net/index.php/api/all/login' //登录
var getregister = 'https://api.rujiaowang.net/api/all/reg_type2'//注册
var getvcode = 'https://api.rujiaowang.net/index.php/api/all/get_user_vcode' //验证码
var checkcode = 'https://api.rujiaowang.net/api/all/checkcode' //验证码校验
var getreset = 'https://api.rujiaowang.net/api/all/editpwd' //重置密码
var getcardlogin = "https://api.rujiaowang.net/api/all/memberReg" //卡登录
var getcardActivate = "https://api.rujiaowang.net/member/activate/member_card" //卡激活
var getisVIP = "https://api.rujiaowang.net/member/activate/card_type" //是否是会员

// 首页
var getcourselist = "https://test.rujiaowang.net/api/Course/course_list" //首页课程展示

// 同步课程
var getgrade = "https://test.rujiaowang.net/api/resource/allgrade" //年级
var getsubject = "https://test.rujiaowang.net/api/resource/getsubject" //科目
var getbook = "https://test.rujiaowang.net/api/resource/getbooklist" //课本
var getchapter = "https://test.rujiaowang.net/api/resource/bookchapter" //章节
var getcourse = "https://test.rujiaowang.net/api/Course/getCourse" //课程列表
var getcourseinfo = "https://test.rujiaowang.net/api/Course/getCourse_info" //课程详情
var getviewcount = "https://test.rujiaowang.net/api/Course/course_count" //流量增加

//专题课程 
var getStype = "https://test.rujiaowang.net/api/Course/special_type" //专题
var getSclassify = "https://test.rujiaowang.net/api/Course/special_classify" //分类
var getScourse = "https://test.rujiaowang.net/api/Course/special_course" //课程
var getSlist = "https://test.rujiaowang.net/api/Course/special_list" //专题课程列表

// 充值中心
var getcardList = "https://api.rujiaowang.net/member/activate/member" //卡列表
var getPayOrder = "https://api.rujiaowang.net/api/rjwpay/createPayOrder" //生成订单
var getdoPayOrder = "https://api.rujiaowang.net/api/rjwpay/doPayOrder" //支付

//组卷
var getErrorsBySubject = 'https://test.rujiaowang.net/api/errorsbook/getErrorsBySubject';
var getGradeSegment = "https://test.rujiaowang.net/api/Label/getGradeSegment" //获取学段
var getSubjectBygsid = "https://test.rujiaowang.net/api/Label/getSubjectBygsid" // 根据学段获取科目
var getPoints = "https://test.rujiaowang.net/api/Label/getPoints" // 根据学段科目获取知识点
var getQuestionType = "https://test.rujiaowang.net/api/question/getQuestionType" //获取当前科目的题目类型
var allgrade = "https://test.rujiaowang.net/api/resource/allgrade"; //获取所有年级列表
var getsubject = "https://test.rujiaowang.net/api/resource/getsubject"; //获取所有科目列表
var getbooklist = "https://test.rujiaowang.net/api/resource/getbooklist"; //获取所有课本列表
var bookchapter = "https://test.rujiaowang.net/api/resource/bookchapter"; //获取所有章节列表
// var getQuesList = "https://test.rujiaowang.net/api/question/getQuesList"; // 获取试题列表
var getPointsQues = "https://test.rujiaowang.net/api/Question/getPointsQues"; // 获取知识点试题列表
var getChaptersQues = "https://test.rujiaowang.net/api/question/getChaptersQues"; // 获取章节试题列表
var GetUserExam = 'https://test.rujiaowang.net/api/exam/exam/GetUserExam'; // 获取指用户完成组卷的列表
var GetByIdExam = 'https://test.rujiaowang.net/api/exam/exam/GetByIdExam'; // 根据试卷id获取试卷详情
var getDownload = 'https://test.rujiaowang.net/api/exam/exam/htmlToWord'; //下载试卷
var saveexam = 'https://test.rujiaowang.net/api/exam/exam/SaveExam' //保存试卷
var finishexam = 'https://test.rujiaowang.net/api/exam/exam/finishExam' //提交试卷
var GetQuestionList = 'https://test.rujiaowang.net/api/collection/GetQuestionList' //查看我的收藏
var GetQuestionById = 'https://test.rujiaowang.net/api/question/GetQuestionById' //获取指定id的试卷详情
var AddQuestion = 'https://test.rujiaowang.net/api/collection/AddQuestion' //增加收藏题
var DelQuestion = 'https://test.rujiaowang.net/api/collection/DelQuestion' //删除收藏题
//资源中心
var allgrade = "https://test.rujiaowang.net/api/resource/allgrade"; //获取所有年级列表
var getsubject = "https://test.rujiaowang.net/api/resource/getsubject"; //获取所有科目列表
var getbooklist = "https://test.rujiaowang.net/api/resource/getbooklist"; //获取所有课本列表
var bookchapter = "https://test.rujiaowang.net/api/resource/bookchapter"; //获取所有章节列表
var getresoursetype = "https://test.rujiaowang.net/api/resource/getresoursetype"; //获取所有资源类型
var getformatype = "https://test.rujiaowang.net/api/resource/getformatype"; //获取所有资源格式
var recommendfiles2 = " https://test.rujiaowang.net/api/recommend/recommendfiles2"; //获取年级最新/最热推荐列表
var selresource = "https://test.rujiaowang.net/api/resource/selresource"; //获取所有资源
var fileView = 'https://test.rujiaowang.net/index.php/api/resource/fileview' //文件浏览次数
var fileDown = 'https://test.rujiaowang.net/index.php/api/resource/filedown' //文件下载次数
export default {
    getresoursetype,
    getformatype,
    recommendfiles2,
    selresource,
    DelQuestion,
    fileView,
    AddQuestion,
    fileDown,
    GetQuestionById,
    GetQuestionList,
    finishexam,
    saveexam,
    getDownload,
    bookchapter,
    GetUserExam,
    GetByIdExam,
    getPointsQues,
    getChaptersQues,
    getErrorsBySubject,
    getGradeSegment,
    getSubjectBygsid,
    getPoints,
    getQuestionType,
    allgrade,
    getbooklist,
    getlogin,
    getSlist,
    getStype,
    getSclassify,
    getScourse,
    getregister,
    getvcode,
    checkcode,
    getcourselist,
    getreset,
    getcardlogin,
    getcardActivate,
    getisVIP,
    getgrade,
    getsubject,
    getbook,
    getchapter,
    getcourse,
    getcardList,
    getPayOrder,
    getdoPayOrder,
    getcourseinfo,
    getviewcount
}
