import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  // 学习卡激活
  {
    path: '/cardActivation',
    name: 'cardActivation',
    component: () =>
      import('../views/cardActivation.vue')
  },
  // 充值中心
  {
    path: '/voucherCenter',
    name: 'voucherCenter',
    component: () =>
      import('../views/voucherCenter.vue')
  },
  // 注册
  {
    path: '/register',
    name: 'register',
    component: () =>
      import('../views/login/register.vue')
  },
  // 忘记密码
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () =>
      import('../views/login/forgetPassword.vue')
  },
  // 同步课程
  {
    path: '/synchronous',
    name: 'synchronous',
    redirect: '/synchronous/courseList',
    component: () =>
      import('../views/synchronousCourse/index.vue'),
    children: [
      //课程首页
      {
        path: '/synchronous/courseList',
        name: 'courseList',
        component: () =>
          import('../views/synchronousCourse/courseList.vue')
      },
      // 详情页
      {
        path: '/synchronous/details',
        name: 'details',
        component: () =>
          import('../views/synchronousCourse/details.vue')
      },
    ],
  },
  // 专题课程
  {
    path: '/topiccourse',
    name: 'topiccourse',
    redirect: '/topiccourse/courseindex',
    component: () =>
      import('../views/topiccourse/index.vue'),
    children: [
      //课程首页
      {
        path: '/topiccourse/courseindex',
        name: 'courseindex',
        component: () =>
          import('../views/topiccourse/courseindex.vue')
      },
      // 详情页
      {
        path: '/topiccourse/topicdetails',
        name: 'details',
        component: () =>
          import('../views/topiccourse/topicdetails.vue')
      },
    ],
  },
  // 组卷系统
  {
    path: '/testPaper',
    name: 'testPaper',
    redirect: '/testPaper/ChapterTopics',
    component: () =>
      import('../views/testPaper'),
    children: [{
        path: '/testPaper/KnowledgTopics', //知识点
        name: 'KnowledgTopics',
        component: () =>
          import('../views/testPaper/KnowledgTopics')
      },
      {
        path: '/testPaper/ChapterTopics', //章节
        name: 'ChapterTopics',
        component: () =>
          import('../views/testPaper/ChapterTopics')
      },
      {
        path: '/testPaper/SmartTopics', //智能
        name: 'SmartTopics',
        component: () =>
          import('../views/testPaper/SmartTopics')
      },
      {
        path: '/testPaper/FavoriteTopics', //收藏夹
        name: 'FavoriteTopics',
        component: () =>
          import('../views/testPaper/FavoriteTopics')
      },
      {
        path: '/testPaper/PersonalTopics', //我的（个人中心）
        name: 'PersonalTopics',
        component: () =>
          import('../views/testPaper/PersonalTopics')
      },
      {
        path: '/testPaper/examPaper', //试卷页面
        name: 'examPaper',
        component: () =>
          import('../views/testPaper/examPaper.vue')
      },
    ]
  },
  //资源中心
  {
    path: '/resource',
    name: 'resource',
    redirect: '/resource/resindex',
    component: () =>
      import('../views/resource/index.vue'),
    children: [
      {
        path: '/resource/resindex',
        name: 'resindex',
        component: () =>
          import('../views/resource/resindex.vue')
      },// 详情页
      {
        path: '/resource/details',
        name: 'details',
        component: () =>
          import('../views/resource/details.vue')
      },
    ],
  },
]


const router = new VueRouter({
  routes
})
// 全局路由守卫
router.beforeEach((to, from, next) => {
  if (!localStorage.getItem('loginInfo')) {
    if (to.path == '/voucherCenter') {
      next('/login');
    }
    if (to.path == '/cardActivation') {
      next('/login');
    }
    if (to.matched[0].path == '/testPaper') {
      next('/login');
    }
  }
  next()
})

export default router