<template>
  <div class="boxf">CopyRight @ 山东大课堂 2021</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<style lang='less' scoped>
.boxf {
  text-align: center;
  font-size: 14px;
  color: #87898d;
  height: 40px;
}
</style>
